// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-flux-js": () => import("./../src/pages/portfolio/flux.js" /* webpackChunkName: "component---src-pages-portfolio-flux-js" */),
  "component---src-pages-portfolio-maptransform-js": () => import("./../src/pages/portfolio/maptransform.js" /* webpackChunkName: "component---src-pages-portfolio-maptransform-js" */),
  "component---src-pages-portfolio-nexus-js": () => import("./../src/pages/portfolio/nexus.js" /* webpackChunkName: "component---src-pages-portfolio-nexus-js" */),
  "component---src-pages-portfolio-nutritiontransform-js": () => import("./../src/pages/portfolio/nutritiontransform.js" /* webpackChunkName: "component---src-pages-portfolio-nutritiontransform-js" */),
  "component---src-pages-portfolio-prism-js": () => import("./../src/pages/portfolio/prism.js" /* webpackChunkName: "component---src-pages-portfolio-prism-js" */),
  "component---src-pages-portfolio-rgbcmyk-js": () => import("./../src/pages/portfolio/rgbcmyk.js" /* webpackChunkName: "component---src-pages-portfolio-rgbcmyk-js" */),
  "component---src-pages-portfolio-solarcssystem-js": () => import("./../src/pages/portfolio/solarcssystem.js" /* webpackChunkName: "component---src-pages-portfolio-solarcssystem-js" */),
  "component---src-pages-portfolio-tax-design-system-js": () => import("./../src/pages/portfolio/tax-design-system.js" /* webpackChunkName: "component---src-pages-portfolio-tax-design-system-js" */),
  "component---src-pages-portfolio-trianglefusion-js": () => import("./../src/pages/portfolio/trianglefusion.js" /* webpackChunkName: "component---src-pages-portfolio-trianglefusion-js" */),
  "component---src-pages-portfolio-uranucss-js": () => import("./../src/pages/portfolio/uranucss.js" /* webpackChunkName: "component---src-pages-portfolio-uranucss-js" */)
}

